html {
  box-sizing: border-box;
}

body {
  font:15px/1.5em $standard-font-family;
  position: relative;
  color:$grey;
  @media (max-width:900px) { font-size:14px; line-height: 1.5em; }
  @media (max-width:600px) { font-size:13px; line-height: 1.5em; }
  background:#21252F;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  cursor: pointer;
}

strong {
  font-weight:700;
}


fieldset {
  border:0;
  margin:0;
  padding:0;
}

img {
  max-width:100%;
}

.center { text-align:center; }
.clear { clear:both; }

#page-wrap {
  position: relative;
}

#page-wrap-inner {
  position: relative;
  right: 0;
  z-index: 100;
  width: 100%;
  transition: right 0.3s ease;
  background:#F2F2F2;
  min-height: 100vh;
  a {
    position: relative;
    &:active {
      top:1px;
    }
  }
  &.push {
    right:321px;
  }
}

#productFeatureLoader  { display:none; }


#loader {
  display:block;
  margin:35px auto;
}

#zero {
  display:none;
  text-align:center;
  color:#fff;
  padding:35px 0;
}

.default-pointer {
  cursor: default;
}

.hide-from-mobile {
  display: block;
  @media(max-width:900px) {
    display:none;
  }
}

.show-only-on-mobile {
  display: none;
  @media(max-width:900px) {
    clear:both;
    display:inline-block;
  }
}

.loader {
    display: none;
}

.general-txt {
  letter-spacing: 0.38px;
  font-style: normal;
  font-weight: 400;
  p {
    margin:1.5em 0 0;
  }
  a {
    text-decoration: underline;
    color:$pink;
  }
  ul {
    margin:30px 18px;
    list-style:none;
    li {
      margin:7px 0;
      position: relative;
      line-height:1.7;
      &:before {
        vertical-align: middle;
        display: inline-block;
        width: 5px;
        height: 5px;
        content: " ";
        border-radius:50%;
        background:$pink;
        margin-right:10px;
        position: absolute;
        left:-15px;
        top:9.5px;
      }
    }
  }
}

.content-wrap {
  padding:90px 0;
  @media(max-width:900px) { padding:75px 0; }
  @media(max-width:600px) { padding:60px 0; }
  @media(max-width:450px) { padding:45px 0; }
  h1 {
    font-size: 33px;
    color: #CA2059;
    letter-spacing: 0;
    line-height:1.3;
    margin-bottom:25px;
    @media(max-width:900px) { font-size:30px; }
    @media(max-width:600px) { font-size:27px; }
    @media(max-width:450px) { font-size:24px; }
  }
  .summary{
    font-family: "roboto",sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: rgba(101,101,101,0.70);
    letter-spacing: 0;
    line-height: 1.75;
    max-width:640px;
    @media(max-width:600px) { font-size:14px; }
    @media(max-width:450px) { font-size:13px; }
  }
  p {

  }
  a.btn {

  }
  &.alt-row {
    background:#FAFAFA;
    h2 {
      font-size: 33px;
      color: #2D2D2D;
      letter-spacing: 0;
      line-height: 1.3;
      margin:0 0 25px;
    }
  }
  .content-wrap-inner-txt {
    padding-bottom:40px;
    h2 {
      font-size: 28px;
      color: #CA2059;
      letter-spacing: 0;
      line-height: 1.3;
      margin-bottom: 25px;
      @media(max-width:900px) { font-size:25px; }
      @media(max-width:600px) { font-size:22px; }
      @media(max-width:450px) { font-size:20px; }
    }
     p {
       max-width:800px;
       margin:0 auto;
     }
  }
}
