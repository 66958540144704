.homepage {
  .home-header {
    .col {
      max-width: 100%;
      text-align: center;
      h1 {

      }
      div {
        font-size: 15px;
        color: #545454;
        letter-spacing: 0;
        line-height: 1.75;
        margin:0 0 20px;
        p {
          margin:0;
        }
      }

    }
    a.btn {
      background-color:#33343C;
      max-width:158px;
      width:100%;
      text-align:center;
    }
    .triangle {
      height:660px;
      background-image:url('../imgs/traingle.png');
      background-repeat:no-repeat;
      background-position: center;
      width:100%;
    }

  }

   //// SVG CHART ///

    /* SVG Line Chart */
    .ct-chart {
      width:calc(50% + 65px);
      position: absolute;
      right:-5%;
      top:108px;
      @media(max-width:1100px) {
        top:92px;
      }
      @media(max-width:1000px) {
        top:85px;
      }
      @media(max-width:960px) {
        top:57px;
      }
      @media(max-width:930px) {
        display:none;
      }
    }
    .ct-series-a {
      stroke-dasharray: 1093;
      stroke-dashoffset: 1093;
      fill: none;
      stroke: #fff;
      animation: draw 3.5s 0s ease both;
      .ct-line {
        stroke:rgba(white, 0.72);
        stroke-width:1px;
      }
      .ct-circle {
        stroke:#fff;
        stroke-width:1px;
        fill:#D61F63;
        &:nth-child(10) {
          stroke-width:0;
          display: none;
        }
      }
    }
    .ct-series-b {
      stroke-dasharray: 1093;
      stroke-dashoffset: 1093;
      fill: none;
      stroke: #fff;
      animation: draw 3.5s 0s ease both;
      .ct-line {
        stroke:rgba(white, 0.2);
        stroke-width:1px;
      }
      .ct-circle {
        display:none;
      }
    }

    .ct-labels {
      display:none;
    }
    .ct-grids line {
      display: none;
    }

    @keyframes draw {
      to {
        stroke-dashoffset: 0;
      }
    }

  .homeintrotext{
    .general-txt{
      max-width: 80%;
      margin: 0 auto;
      @media(max-width:900px) { max-width: 100%; }
      p{
        font-size: 22px;
        color: rgb(202, 31, 89);
        letter-spacing: 0;
        line-height: 1.35;
      }
    }
  }




  .featured-portfolio {
    background:$navy;
    position:relative;
    padding:185px 0 135px;
    @media(max-width:900px) { padding:155px 0 105px; }
    @media(max-width:600px) { padding:75px 0; }
    @media(max-width:450px) { padding:50px 0; }

    .row {
      margin:0 -20px;
      @media(max-width:600px) {
        width:90%;
        margin: 0 auto;
      }
      .col {
        display:inline-block;
        &:nth-child(2) {
          vertical-align: top;
          max-width:895px;
          float:left;
          @media(max-width:1160px) {
            max-width:none;
            float:none;
            width:100%;
            text-align:center;
          }
          //width:calc(100% - 460px);
          //@media(max-width:1300px) {
          //  width:calc(100%);
           // text-align:center;
          //}
          .portfolio-item {
            margin:18px 12.5px;
            //max-width:244px;
          }
        }
        &:nth-child(3) {
          float:right;
          max-width:calc(100% - 895px);
          padding-left:70px;
        }
        &:nth-child(1),
        &:nth-child(3) {
          padding-top:20px;
          h3 {
            font-size: 33px;
            color:$pink;
            letter-spacing: 0;
            line-height: 2.5;
            font-style: normal;
            font-weight: 700;
            max-width:260px;
          }
          p {
            font-size: 15px;
            color: #FFFFFF;
            letter-spacing: 0;
            line-height: 1.75;
            font-style: normal;
            font-weight: 300;
            max-width:260px;
            margin:0 0 10px;
          }
          a.btn {
            background:#34363F;
            color:#fff;
            font-size: 14px;
            margin:10px 0;
          }
          span {
            color:#fff;
            font-size:21px;
            cursor: pointer;
          }

        }

        &:nth-child(1) {
          display:none;

          @media(max-width:1160px) {
            display:block;
            float:none;
            width:100%;
            text-align:center;
            padding-left:0;
            padding-right:0;
            margin-bottom:30px;
            h3 { max-width:none; }
            p { max-width:none; }
          }
        }
        &:nth-child(3) {
          @media(max-width:1160px) {
            display:none;
          }
        }

        .slick-list {
          padding:0 !important;
        }

      }
    }
    &:after {
      content: " ";
      height:66px;
      width:75%;
      position: absolute;
      top:0;
      right:0;
      background:#F2F2F2;
      @media(max-width:600px) { display:none; }
    }
    &:before {
      width: 0;
      height: 0;
      border-top: 66px solid #F2F2F2;
      border-left: 50px solid $navy;
      content: " ";
      position: absolute;
      top:0;
      z-index:100;
      left:25%;
      @media(max-width:600px) {
        display: none;
      }
    }
}




  .triangle-wrap {
    position: relative;
    margin:75px auto 0;
    max-width:1300px;
    left:-155px;
    position: relative;
    display:flex;
    @media(max-width:1250px) { left:-190px; }
    @media(max-width:1200px) { left:0; }
    @media(max-width:1020px) {
      display:none;
    }
    .col {
      max-width:none;
      vertical-align: top;
      &:nth-child(1) {
        position: relative;
        display: inline-block;
        vertical-align: top;
        position: relative;
        height:425px;
        width:calc(100% - 331px);
        @media(max-width:1200px) {
          width:calc(100% - 500px);
        }
        svg {
          width: 754px;
          //position: absolute;
          //left:-190px;
          right:0;
          overflow: hidden;
          position: relative;
          @media(max-width:1200px) {
            right:auto;
            left:-190px;
          }

          path {
            transition: .3s fill;
            cursor: pointer;
            &#triangle1 {
              fill: #CA2059;
              &:hover {
                fill:rgba(#CA2059, 0.8);
              }
            }
            &#triangle2 {
              fill:#871E41;
              &:hover {
                fill:rgba(#871E41, 0.8);
              }
            }
            &#triangle3 {
              fill:#651D37;
              &:hover {
                fill:rgba(#651D37, 0.8);
              }
            }
          }
        }

        .triangle-lbl {
          position: absolute;
          right:0;
          padding-bottom:3px;
          border-bottom:1px rgba(#979797, 0.23) solid;
          font-size: 13.5px;
          letter-spacing: 0;
          line-height: 1.35;
          color:rgba(#272727,0.3);
          font-style: normal;
          font-weight: 500;
          @media(max-width:1200px) {
            right:-100px;
          }
          cursor: pointer;
          &:after {
            content: " ";
            width:9px;
            height:9px;
            display: block;
            position: absolute;
            right:0;
            bottom:-5px;
            background:#D61F63;
            border-radius:50%;
            border:2px #FFFFFF solid;
          }
          &#triangle1-lbl {
            top:15px;
            width:595px;
            width:calc(100% - 305px);
            @media(max-width:1200px) {
              width:calc(100% - 15px);
            }
            //@media(max-width:1300px) { width:545px; }
            //@media(max-width:1250px) { width:505px; }
          }
          &#triangle2-lbl {
            top:130px;
            width:385px;
            width:calc(100% - 515px);
            @media(max-width:1200px) {
              width:calc(100% - 215px);
            }
            //@media(max-width:1300px) { width:335px; }
            //@media(max-width:1250px) { width:295px; }
          }
          &#triangle3-lbl {
            top:250px;
            width:calc(100% - 695px);
            @media(max-width:1200px) {
              width:calc(100% - 405px);
            }
            //@media(max-width:1300px) { width:160px; }
            //@media(max-width:1250px) { width:125px; }
          }
          &.active {
            color:#BD2050;
            &:after {
              width:9px;
              height:9px;
              border:1px #FFFFFF solid;
            }

          }
        }

      }
      &:nth-child(2) {
        display: inline-block;
        margin-left:60px;
        vertical-align: top;
        @media(max-width:1200px) {
          position: absolute;
          right:2%;

        }
        .col-items-wrap {
          width:331px;
          position: relative;
          .col-row {
            height:42px;
            line-height:42px;
            display: flex;
            width:100%;
            font-size:14px;
            font-style: normal;
            font-weight: 500;
            .col-lbl {
              width:calc(100%);
              background: rgba(#878787, 0.11);
              border: 1px solid rgba(#979797, 0.11);
              border-left-width: 10px;
              margin:0;
              line-height:42px;
              padding:0 15px;
              color:#808084;
            }
            /*
            .col-value {
              width:80px;
              background:#BD2050;
              padding:0 18px;
              color:#fff;
              margin:0;
              line-height:42px;
            }*/
          }
          &#pyramid_stats_1 {
            top:10px;
          }
          &#pyramid_stats_2 {
            top:127px;
          }
          &#pyramid_stats_3 {
            top:244px;
          }
        }
      }
    }
  }

  .mobile-triangle {
    display: none;
    @media(max-width:1020px) {
      display: block;
    }
    .tab {
      position: relative;
      margin-bottom: 1px;
      width: 100%;
      overflow: hidden;
      input {
        position: absolute;
        opacity: 0;
        z-index: -1;
      }
      label {
        position: relative;
        display: block;
        padding: 0 0 0 1em;
        font-weight: bold;
        line-height: 3;
        color:#fff;
        cursor: pointer;
      }
      &:nth-child(1) {
        background:#CA2059;

      }
      &:nth-child(2) {
        background:#871E41;

      }
      &:nth-child(3) {
        background:#651D37;
      }
    }
    .tab-content {
      max-height: 0;
      overflow: hidden;
      background:#e7e7e7;
      transition: max-height .35s;
      p {
        margin: 1em;
      }
      ul {
        margin:1.25em 45px;
        list-style-type: disc;
        li {
          margin:2px 0;
        }
      }
    }

    /* :checked */
    input:checked ~ .tab-content {
      max-height: 100em;
    }
    /* Icon */
    label::after {
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      width: 3em;
      height: 3em;
      line-height: 3;
      text-align: center;
      -webkit-transition: all .35s;
      -o-transition: all .35s;
      transition: all .35s;
    }
    input[type=checkbox] + label::after {
      content: "+";
    }
    input[type=radio] + label::after {
      content: "\25BC";
    }
    input[type=checkbox]:checked + label::after {
      transform: rotate(315deg);
    }
    input[type=radio]:checked + label::after {
      transform: rotateX(180deg);
    }

  }






  .two-col {
    position:relative;
    .row {
      display:flex;
      margin:0;
      @media(max-width:900px) {
        flex-direction: column;
      }
      .col {
        width:50%;
        &:nth-child(2) {
          background-size:cover;
          background-repeat:no-repeat;
          background-position: center;
          img {
            opacity:0;
          }
        }
        &:nth-child(1) {
          display:flex;
          align-items:center;
          background: #fff;
          .inner {
            width:80%;
            margin:0 auto;
            max-width:350px;
          }
        }
        @media(max-width:900px) {
          width:100%;
          flex-direction: column;
          &:nth-child(1) {
            padding-top:100px;
            padding-bottom:35px;
            text-align:center;
            .inner {
              max-width:none;
              width:90%;
            }
          }
        }
        h4 {
          font-size: 17px;
          color: #1F212A;
          letter-spacing: 0;
        }
        h3 {
          font-size: 33px;
          letter-spacing: 0;
          line-height:1.1;
          max-width:275px;
          margin: 0 0 20px;
          @media(max-width:900px) {
            max-width:none;
          }
          @media(max-width:600px) {
            font-size:30px;
          }
        }
        p {
          font-size: 15px;
          color: #545454;
          letter-spacing: 0;
          line-height: 1.75;
          margin: 0 0 15px;
        }
        a.btn {
          background:$navy;
          color:#fff;
        }
      }
    }
    &:after {
      content: " ";
      height:66px;
      width:450px;
      position: absolute;
      top:0;
      right:0;
      //background:$navy;
      z-index:100;
      @media(max-width:1080px) {
        display:none;
      }
    }
    &:before {
      width: 85px;
      height: 95px;
      //background:$navy;
      content: " ";
      position: absolute;
      top: -45px;
      z-index: 100;
      right: 410px;
      transform: rotate(-45deg);
      @media(max-width:1080px) {
        display:none;
      }
    }

  }

  .featured-portfolio-items {
    display:flex;
    flex-wrap: wrap;
    a.portfolio-item {
      max-width:255px;
      width:calc(33% - 30px);
      display:block;
      .txt-wrap {
        display:block;
        overflow: hidden;
      }
      .btn-wrap {
        display:none;
      }
    }
    @media(max-width:700px) {
      align-content: center;
      justify-content: center;
      a.portfolio-item {
        width:calc(100%);
      }
    }
  }

  .concept-wrap {
    .footer {
      display: block;
    }
  }

  .news-and-events-items {
    background:#F2F2F2;
    padding:100px 0 115px;
    text-align:center;
    position: relative;
    @media(max-width:900px) { padding:135px 0 100px; }
    @media(max-width:600px) { padding:95px 0 75px; }
    @media(max-width:450px) { padding:50px 0 50px; }
    h3 {
      font-size: 27px;
      color:$pink;
      letter-spacing: 0;
      line-height: 1.35;
      margin:0 0 5px;
    }
    p {
      font-size: 15px;
      color: #545454;
      letter-spacing: 0;
      line-height: 1.75;
      max-width:605px;
      margin:0 auto 35px;
    }
    .item {
      p {
        margin:0;
      }
    }
    .btn-wrap {
      margin:35px 0 0;
      a.btn {
        background: #2D2D2D;
        border-radius: 4px;
        margin:0 5px;
        &:nth-child(2) {
          background:#3A3C44;
        }
      }
    }
  }
}



@media(max-width:1080px) {
  .homepage {
    .concept-wrap {
      padding-top:30px;
      &:after {
        display:none;
      }
      &:before {
        display:none;
      }
    }
  }
}
