header.page-header {
  background: $navy;
  padding: 45px 0;
  position: relative;
  overflow: hidden;
  max-height: 660px;
  @media (max-width: 960px) {
    max-height: none;
  }
  .inner-wrap {
    max-width: 1600px;
    margin: 0 auto;
    a {
      color: #fff;
    }
    ul#nav {
      text-align: right;
      @media (max-width: 960px) {
        display: none;
      }
      li {
        display: inline-block;
        margin-left: 30px;
        text-transform: uppercase;
        font-size: 14px;
        @media (max-width: 1100px) {
          font-size: 12px;
        }
        font-style: normal;
        font-weight: 700;
        letter-spacing: 1px;
        &:nth-child(1) {
          margin-left: 0;
        }
        a {
          color: rgba(#fff, 0.68);
          transition: color 0.5s;
          &:hover {
            text-decoration: none;
            color: #fff;
          }
        }
        &.active {
          padding-bottom: 3px;
          border-bottom: 2px #76214a solid;
        }
      }
    }
    .page-header-inner {
      padding: 110px 0;
      @media (max-width: 900px) {
        padding: 90px 0;
      }
      @media (max-width: 600px) {
        padding: 75px 0;
      }
      @media (max-width: 450px) {
        padding: 60px 0;
      }
      h2 {
        font-size: 74px;
        color: #d61f63;
        letter-spacing: 0;
        line-height: 1.2;
        margin-top: 15px;
        font-weight: 700;
        text-transform: uppercase;
        display: block;
        @media (max-width: 1100px) {
          font-size: 67px;
        }
        @media (max-width: 1000px) {
          font-size: 64px;
        }
        @media (max-width: 900px) {
          font-size: 60px;
        }
        @media (max-width: 600px) {
          font-size: 42px;
        }
        @media (max-width: 450px) {
          font-size: 36px;
        }
        span {
          display: block;
          &.top {
            background: -webkit-linear-gradient(left, #a41a55, #e11e6d);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          &.btm {
            color: white;
            font-style: normal;
            font-weight: 300;
          }
        }
      }
      p {
        font-size: 15px;
        color: #ffffff;
        letter-spacing: 1.25px;
        font-style: normal;
        font-weight: 300;
      }
      .dwn-arrow {
        margin-top: 60px;
        position: relative;
        left: -9px;
        animation: bounce 1.5s infinite;
        img {
        }
        @media (max-width: 900px) {
          margin-top: 40px;
        }
      }
      @keyframes bounce {
        0% {
          top: 0;
        }
        50% {
          top: 3px;
        }
        100% {
          top: 0;
        }
      }
      .col {
        &:nth-child(2) {
        }
      }
    }
  }
  &:after {
    content: " ";
    height: 66px;
    width: 33%;
    position: absolute;
    bottom: 0;
    right: 0;
    background: #f2f2f2;
    @media (max-width: 600px) {
      display: none;
    }
  }
  &:before {
    width: 0;
    height: 0;
    border-top: 66px solid $navy;
    border-right: 50px solid #f2f2f2;
    content: " ";
    position: absolute;
    bottom: 0;
    right: 33%;
    @media (max-width: 600px) {
      display: none;
    }
  }

  &.minimum {
    min-height: auto;
    height: auto;
    &:after {
      display: none;
    }
    &:before {
      display: none;
    }
    .inner-wrap {
      display: flex;
      justify-content: space-between;
    }
  }
}

/* Navigation Button */
#mobile-burger-btn {
  border-radius: 4px;
  cursor: pointer;
  display: none;
  margin: 0;
  text-align: center;
  transition: right 0.5s;
  z-index: 100;
  padding-top: 2px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  color: $pink;
  @media (max-width: 960px) {
    display: inline-block;
  }
  i {
    font-size: 32px;
    position: relative;
    top: 17px;
    @media (max-width: 1060px) {
      font-size: 30px;
      top: 5px;
    }
    &:nth-child(2) {
      display: none;
    }
  }
  @media (max-width: 1060px) {
    z-index: 9999;
    position: fixed;
    right: 15px;
    top: 15px;
  }
  &.menu-open {
    i {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        display: inline-block;
      }
    }
  }
}
