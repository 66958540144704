.column {
    float: left;
}
.size-1of4 {
    width: 25%;
}
.size-1of3 {
    width: 33.333%;
}
.size-1of2 {
    width: 50%;
}

@media screen and (max-width: 600px) {
    #grid[data-columns]::before {
        content: '1 .column';
    }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
    #grid[data-columns]::before {
        content: '2 .column.size-1of2';
    }
}

@media screen and (min-width: 901px) {
    #grid[data-columns]::before {
        content: '3 .column.size-1of3';
    }
}
