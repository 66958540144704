
.fancybox-slide > * {
  padding:0;
}
.dialog-details-wrap {
  display:none;
  width:90%;
  max-width: 885px;
  text-align: center;
  .fancybox-close-small {
    background: #9F1C44;
    height:45px;
    width:45px;
    &:after {
      color:white;
      height:35px;
      width:35px;
      font-size:20px;
      top:6.5px;
      right:4px;
      font-family: FontAwesome;
      content: "\f00d";
    }
    @media(max-width:450px) {
      width:35px;
      height:35px;
      &:after {
        width:25px;
        height:25px;
        top:1.5px;
      }
    }
    &:hover {
      background:rgba(#9F1C44, 0.9);
      &:after {
        color:white;
        background:none;
      }
    }
  }
  .wrap {
    display:flex;
    background:#fff;
    @media(max-width:900px) {
      flex-direction: column;
    }
    .col {
      width:50%;
      @media(max-width:900px) {
        width:100%;
      }
      &.cover {
        background-size:cover;
        background-position: top center;
        background-repeat: no-repeat;
        min-height:300px;
        img {
          opacity:0;
        }
      }
      &:nth-child(1) {
        display:flex;
        justify-content:center;
        align-items:center;
        padding:70px;
      }
      &:nth-child(2) {
        background: #F2F2F2;
        padding:70px 50px 50px;
        text-align:left;
        position: relative;
        @media(max-width:900px) {
          position: static;
        }
        @media(max-width:600px) {
          padding:60px;
        }
        @media(max-width:450px) {
          padding:50px;
        }
        @media(max-width:400px) {
          padding:40px;
        }
      }
      .controls-wrap {
        position: absolute;
        top:0;
        right:45px;
        width:90px;
        height:45px;
        display:flex;
        span {
          width:50%;
          height:45px;
          background:#BD2050;
          display:inline-block;
          text-align:center;
          line-height: 42px;
          cursor: pointer;
          color:#fff;
          font-size:20px;
          position: relative;
          &:nth-child(1) {
            border-right:1px rgba(#EDF4FF, 0.11) solid;
          }
          &:hover {
            background:rgba(#BD2050, 0.9);
          }
        }
        @media(max-width:450px) {
          width:70px;
          right:35px;
          span {
            height:35px;
            line-height:33px;
          }
        }
      }
      h3 {
        font-size: 33px;
        color: #BD2050;
        letter-spacing: 0;
        line-height: 1.3;
        font-weight:500;
        font-style: normal;
        padding:0;
        margin:0;
        margin-bottom:15px;
        @media(max-width:600px) {
          font-size:30px;
        }
        @media(max-width:450px) {
          font-size:27px;
        }
      }
      .desc {
          font-size: 15px;
          color: #656565;
          letter-spacing: 0;
          line-height: 1.75;
          @media(max-width:600px) {
            font-size:14px;
          }
          @media(max-width:450px) {
            font-size:13px;
          }
          p {
            margin:1.5em 0 0;
            &:nth-child(1) {
              font-weight:700;
            }
          }
          ul {
            margin:10px 0 10px 25px;
            list-style-type: disc;
            li {

            }
          }
      }
      .btn-wrap {
        margin-top:35px;
      }
      .btn {
          background: #2D2D2D;
          border-radius: 4px;
          width:100%;
          max-width:158px;
          color:#fff;
          text-align:center;
          margin-top:35px;
          &[href=""] {
            display:none;
          }
      }
      a.txt-btn {
        display:inline-block;
        margin-bottom: 5px;
        color: rgb(192, 77, 111);
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
        &[href=""] {
          display:none;
        }
      }
      a.social-btn {
        font-size:36px;
        color:#1F5884;
        i {
          color:#1F5884;
        }
        &[href=""] {
          display:none;
        }
      }
    }
  }
}