.map {
  height:475px;
  margin:0;
  width:100%;
  display: block;
  .infoMarker {
    text-align:left;
    strong{
      font-size:16px;
      margin-top:5px;
      margin-bottom:5px;
      display: block;
    }
    .btn {
      padding:10px 15px;
      font-size:12px;
      margin:10px 0;
    }
  }
}
