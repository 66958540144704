#mobile-nav-items {
    display: block;
    position: absolute;
    right: 0;
    width:321px;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: 50;
    text-align:left;
    background:#161920;
    min-height:100vh;
    padding:0 35px 35px;
    .top {
      margin-bottom:25px;
      padding-top:35px;
    }
    ul {
      margin:10px 0;
      li {
        text-transform: uppercase;
        display: block;
        text-align:left;
        border-bottom: 1px solid rgba(white, 0.11);
        font-style: normal;
        font-weight: 300;
        font-size:14px;
        li {
          padding:5px 0;
        }
        a,span {
          color:rgba(#fff, 0.68%);
          display:inline-block;
          line-height: 1.25em;
          padding: 14px 0;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        &.active {
          a { color:$pink; }
        }
      }
      &.second {
        display: none;
        margin-top:0;
        li {
          border-bottom:0;
          display: flex;
          padding:0 24px;
          text-transform: none;
          margin-bottom:5px;
          span {
            padding:0;
            margin-right:8px;
          }
          a {
            padding:0;
            font-size:13px;
            display: inline-block;
          }
        }
      }
      &.open {
        display: block;
      }
    }
    .btm {
      opacity: 0.68;
      font-size: 11px;
      color: #FFFFFF;
      letter-spacing: 0.92px;
      font-style: normal;
      font-weight: 400;
      margin-top:20px;
      margin-bottom: 20px;
      a {
        color: #FFFFFF;
        text-decoration: underline;

      }
    }
}
