.contact-header {
  &.row {
    margin-left:0;
    margin-right:0;
  }
}

  .contact-body {
    display: block;
    &.row {
      margin-left:0;
      margin-right:0;
    }
    .col {
      width:calc(50% - 10px);
      display: inline-block;
      vertical-align: top;
      min-height: 300px;
      @media(max-width:900px) {
        width:100%;
        display:block;
        min-height: auto;
      }
      &:nth-child(1){
        margin-right: 10px;
        @media(max-width:900px) {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
      &:nth-child(2){
        margin-left: 10px;
        @media(max-width:900px) {
          margin-left: 0;
        }
      }
      &:nth-child(1), &:nth-child(2) {
        padding:40px;
        display: inline-block;
        background:$navy;
        color:#fff;
        @media(max-width:600px) { padding:30px; }
        @media(max-width:450px) { padding:25px; }
        h2 {
          font-size: 27px;
          color:$pink;
          letter-spacing: 0;
          line-height:1;
          margin:0 0 25px;
          @media(max-width:900px) { font-size:24px; }
          @media(max-width:600px) { font-size:21px; }
          @media(max-width:450px) { font-size:19px; }
        }
        .location {
          .row {
            display: block;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 1.35;
            margin:10px 0;
            font-style: normal;
            font-weight: 400;
            @media(max-width:600px) { font-size:15px; }
            @media(max-width:450px) { font-size:14px; }
            strong {
              font-family:$title-font-family;
              letter-spacing: 0;
              line-height: 1;
              width:100px;
              display: inline-block;
              vertical-align: top;
              font-style: normal;
              font-weight: 500;
              @media(max-width:450px) {
                width:100%;
                display: block;
                margin-bottom:5px;
              }
            }
            .value {
              display: inline-block;
              vertical-align: top;
              width:calc(100% - 100px);
              @media(max-width:450px) {
                width:100%;
                display: block;
              }
            }
            a {
              color:#fff;
              text-decoration: underline;
              &.btn {
                text-decoration: none;
              }
            }
          }
          a.btn {
            @media(max-width:450px) {
              display: block;
              width:100%;
              margin-top:10px;
              text-align:center;
            }
          }
        }
      }
    }

  }
