.faq-wrap {
  text-align:center;
  padding:120px 0;
  position: relative;
  margin: 0 auto;
  &:before {
    width: 85px;
    height: 95px;
    background: #21252F;
    content: " ";
    position: absolute;
    top: -45px;
    z-index: 100;
    right: 25%;
    transform: rotate(-45deg);
    @media(max-width:600px) {
      display:none;
    }
  }
  &:after {
    content: " ";
    height: 66px;
    width: calc(25% + 39.5px);
    position: absolute;
    top: 0;
    right: 0;
    background: #21252F;
    z-index: 100;
    @media(max-width:600px) {
      display:none;
    }
  }
  h3 {
    font-size: 27px;
    color: #CA2059;
    letter-spacing: 0;
    line-height: 1.35;
    width:90%;
    margin:0 auto;
    @media(max-width:600px) { font-size:23px; }
    @media(max-width:450px) { font-size:20px; }
  }
  .faq-wrap-items {
    max-width:808px;
    margin:50px auto 0;
    counter-reset: number;
    width:90%;
    .faq-item {
      background: #FFFFFF;
      box-shadow: 0 0 6px 0 rgba(0,0,0,0.10);
      border-radius: 4px;
      text-align:left;
      padding:0 0 40px;
      margin-top:30px;
      position: relative;
      @media(max-width:600px) {
        padding-bottom:30px;
      }
      &:nth-child(1) {
        margin-top:0;
      }
      &:active {
        top:1px;
      }
      input {
        position: absolute;
        opacity: 0;
        z-index: -1;
      }
      label { 
        font-size: 18px;
        color: #21252F;
        letter-spacing: 0;
        font-style: normal;
        font-weight: 500;
        margin:0;
        display:block;
        line-height:1.35;
        position: relative;
        cursor: pointer;
        padding-left:40px;
        padding-top:30px;
        padding-right:40px;
        overflow: hidden;
        &:before {
          counter-increment: number;
          content: counter(number) ": ";
          //position: absolute;
          //left:40px;
          //top:30px;
        }
        @media(max-width:600px) {
          padding-left:30px;
          padding-top:20px;
          padding-right:30px;
          font-size:16px;
          //&:before {
          //  left:20px;
          ////  top:21px;
          //}
        }
        @media(max-width:450px) {
          font-size:14px;
        }
      }
      .response { 
        line-height: 1.6;
        color: rgba(101,101,101,0.70);
        letter-spacing: 0;
        font-style: normal;
        font-weight: 300;
        font-size:15px;
        max-height: 0;
        overflow: hidden;
        transition: max-height .35s;
        padding-left:40px;
        padding-right:40px;
        @media(max-width:600px) {
          padding-left:30px;
          padding-right:30px;
          font-size:14px;
        }

        p {
          margin:1.5em 0 0;
        }
        ul {
          margin:10px 0 1.5em;
          list-style: disc;
          li {
            margin:0 25px;
          }
        }
      }
      &:hover {
        label { color: #CA2059; }
      }
      input:checked ~ .response {
        max-height:200em;
      }
      a {
        color: #CA2059;
    text-decoration: underline;
    font-style: normal;
    font-weight: 500;
      }
    }
  }

  a.btn {
    margin-top:35px;
    background:#41454D;
  }
}