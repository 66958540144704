
  .concept-figures-wrap {
    max-width:877px;
    margin:80px auto;
    @media(max-width:900px) { margin:60px auto; }
    @media(max-width:600px) { margin:45px auto; }
    @media(max-width:450px) { margin:30px auto; }
    .col {
      width:140px;
      @media(max-width:600px) {
        margin:0 auto;
        width:100%;
      }
    }
    .concept-figures {
      display:flex;
      justify-content: space-between;
      @media(max-width:600px) {
        flex-direction: column;
        text-align:center;
      }
      .number {
        height:140px;
        line-height:130px;
        font-size: 26px;
        color: #CA2059;
        letter-spacing: 0;
        font-style: normal;
        font-weight: 700;
        //border:7px #ccc solid;
        //border-radius:50%;
        position: relative;
        @media(max-width:600px) {
          height:auto;
          margin-top:25px;
        }
        strong {
          position: absolute;
          top:0;
          left:0;
          width:100%;
        }
        &.start {
          strong { color:#aaa; }
        }
        .mobile-title {
          display:none;
          font-size: 16px;
          color: #CA2059;
          letter-spacing: 0;
          line-height: 1.5;
          font-weight: 700;
          @media(max-width:600px) {
            display:block;
            position: absolute;
            bottom:15px;
            width:100%;
            text-align:center;
          }
        }
      }
      .sep {
        display:block;
        width:calc(50% - 230px);
        @media(max-width:600px) {
          display:none;
        }
        .sep-inner {
          display:block;
          width:0;
          height:7px;
          margin-top:62px;
          transition:width 2s;
          background:url('../imgs/dotted_line.png');
        }
        &.selected {
          .sep-inner {
            width:100%;
          }
        }
      }
    }
    .concept-lbl {
      display:flex;
      justify-content: space-between;
      @media(max-width:600px) {
        display:none;
      }
      .col {
        padding-top:14px;
        .lbl {
          font-size: 16px;
          color: #41454D;
          letter-spacing: 0;
          line-height: 1.5;
          font-style: normal;
          font-weight: 700;
          text-align:center;
        }
      }
    }
  }


  #circle2 strong,
  #circle3 strong, {
  &:after {
    content: "m";

  }
}
