.doc-wrap {
  padding-top:15px;
  h3 {
    font-size: 32px;
    color: #4D4D4D;
    letter-spacing: 0.8px;
    line-height:1;
    font-family: $title-font-family;
    font-style: normal;
    font-weight: 500;
  }
  a {
    background: #F5F5F5;
    border: 1px solid #E2E2E2;
    display: block;
    margin:10px 0;
    padding:5px 10px;
    span.ico-wrap {
      width:50px;
      text-align:center;
      border-right:1px #E2E2E2 solid;
      padding-right:10px;
      display: inline-block;
      i { 
        color:#000D4A; 
        font-size:24px;
      }
     }
    span.txt-wrap { 
      display: inline-block;
      color:#4D4D4D;
      font-size: 18px;
      color: #4D4D4D;
      letter-spacing: 0.82px;
      line-height: 1;
      padding:15px;
      font-size: 18px;
      color: #4D4D4D;
      letter-spacing: 0.82px;
      line-height:1;
      font-family: $title-font-family;
      font-style: normal;
      font-weight: 500;
    }
  }
}