.scotlandbanner {
  background: #d11d66;
  color: #fff;
  padding: 10px 0;
  font-size: 13px;
  a {
    font-size: 13px;
    color: #fff;
  }
}

.inner-wrap {
  max-width: $container + px;
  width: 90%;
  margin: auto;
}

.concept-wrap {
  background: $navy;
  padding: 120px 0 65px;
  position: relative;
  text-align: center;
  @media (max-width: 900px) {
    padding: 90px 0 50px;
  }
  @media (max-width: 600px) {
    padding: 70px 0 50px;
  }
  @media (max-width: 450px) {
    padding: 50px 0 50px;
  }
  h3 {
    color: $pink;
    font-size: 33px;
    color: #ca2059;
    letter-spacing: 0;
    line-height: 1.5;
    font-style: normal;
    font-weight: 500;
    margin: 25px 0;
    @media (max-width: 600px) {
      font-size: 31px;
    }
    @media (max-width: 450px) {
      font-size: 29px;
    }
  }
  p {
    color: white;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    line-height: 1.75;
    font-style: normal;
    font-weight: 300;
    max-width: 770px;
    margin: 0 auto 10px;
  }
  .row {
    display: flex;
    margin: 50px auto;
    max-width: 990px;
    .col {
      margin-left: 22.5px;
      margin-right: 22.5px;
      width: calc(50% - 45px);
      background: #ffffff;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 30px 50px 85px;
      letter-spacing: 0;
      position: relative;
      @media (max-width: 900px) {
        &:nth-child(1) {
          margin-bottom: 22.5px;
        }
      }
      @media (max-width: 600px) {
        padding: 20px 35px 85px;
      }
      .top {
        h4 {
          font-size: 23px;
          color: #ca2059;
          letter-spacing: 0;
          margin-top: 30px;
          @media (max-width: 600px) {
            font-size: 21px;
          }
          @media (max-width: 450px) {
            font-size: 19px;
          }
        }
        h5 {
          background: #f0f0f0;
          border-radius: 100px;
          font-size: 14px;
          color: #474545;
          max-width: 200px;
          margin: 27px auto;
          padding: 15px 0;
          strong {
            font-style: normal;
            font-weight: 500;
            text-transform: uppercase;
          }
        }
        p {
          font-size: 13.5px;
          color: #727272;
          line-height: 2;
        }
      }
      .btm {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-bottom: 30px;
        p {
          font-size: 13.5px;
          color: #5e697d;
          letter-spacing: 0;
          strong {
            font-style: normal;
            font-weight: 500;
            text-transform: uppercase;
          }
        }
        a.btn {
          font-style: normal;
          font-weight: 500;
          text-transform: uppercase;
          max-width: 158px;
          width: 100%;
          font-size: 14px;
          line-height: 1;
        }
      }
    }
    @media (max-width: 900px) {
      flex-direction: column;
      .col {
        width: calc(100% - 45px);
      }
    }
  }
  .footer {
    display: none;
    p {
      max-width: none;
      font-size: 13.5px;
      color: #ffffff;
      letter-spacing: 0;
      line-height: 2;
      margin: 0 0 25px;
    }
    a.btn {
      background: rgba(#eeeeee, 0.16);
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: 0;
      line-height: 1.7;
    }
  }
  &:after {
    content: " ";
    height: 66px;
    width: 25%;
    position: absolute;
    top: 0;
    left: 0;
    background: #f2f2f2;
    @media (max-width: 600px) {
      display: none;
    }
  }
  &:before {
    width: 0;
    height: 0;
    border-top: 66px solid #f2f2f2;
    border-right: 50px solid $navy;
    content: " ";
    position: absolute;
    top: 0;
    left: 25%;
    @media (max-width: 600px) {
      display: none;
    }
  }
}

.figures-header {
  text-align: center;
  padding: 120px 0;
  @media (max-width: 900px) {
    padding: 90px 0;
  }
  @media (max-width: 600px) {
    padding: 70px 0;
  }
  @media (max-width: 450px) {
    padding: 50px 0;
  }
  h1 {
  }
  p {
    font-size: 15px;
    color: rgba(101, 101, 101, 0.7);
    letter-spacing: 0;
    line-height: 1.35;
  }
  &.alt {
    background-color: #fff;
  }
}

.meet-the-team {
  margin-top: 65px;
  h2 {
    font-size: 24px;
    color: #ca2059;
    letter-spacing: 0;
    margin: 0 0 20px;
    @media (max-width: 900px) {
      font-size: 22px;
    }
    @media (max-width: 600px) {
      font-size: 20px;
    }
    @media (max-width: 450px) {
      font-size: 18px;
    }
  }
  .meet-the-team-items {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 0;
    margin-bottom: 50px;
    a.item {
      width: calc(25% - 30px);
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 30px;
      background: #ffffff;
      border: 1px solid #eaeaea;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.13);
      @media (max-width: 1040px) {
        width: calc(33% - 30px);
      }
      @media (max-width: 750px) {
        width: calc(50% - 30px);
      }
      @media (max-width: 420px) {
        width: calc(100%);
      }
      .img-wrap {
        //height:248px;
        overflow: hidden;
        text-align: center;
        img {
          //min-width:100%;
          //max-width:none;
          // margin:0 auto;
          // min-height:248px;
          //height:248px;
          width: 100%;
        }
        @media (max-width: 420px) {
          height: auto;
          img {
            min-height: none;
          }
        }
      }
      .txt-wrap {
        padding: 20px 25px;
        @media (max-width: 600px) {
          padding: 15px 20px;
        }
        @media (max-width: 450px) {
          font-size: 10px 15px;
        }
        h4 {
          font-size: 21px;
          color: #e11e6d;
          letter-spacing: 0;
          line-height: 1.1;
          text-transform: none;
          margin: 0 0 5px;
          @media (max-width: 900px) {
            font-size: 19px;
          }
          @media (max-width: 600px) {
            font-size: 17px;
          }
          @media (max-width: 450px) {
            font-size: 16px;
          }
        }
        h5 {
          font-size: 10px;
          font-weight: 600;
          color: rgba(#21252f, 0.67);
          letter-spacing: 0;
          line-height: 1.25;
          @media (max-width: 900px) {
            font-size: 13px;
          }
          @media (max-width: 600px) {
            font-size: 12px;
          }
          @media (max-width: 450px) {
            font-size: 11px;
          }
        }
      }
      &:hover {
        text-decoration: none;
      }
      &:focus {
        text-decoration: none;
      }
    }
  }
}

.backgroundinfo {
  display: block;
  &.row {
    margin-left: 0;
    margin-right: 0;
  }
  .col {
    width: calc(50% - 20px);
    display: inline-block;
    vertical-align: top;
    min-height: 300px;
    @media (max-width: 900px) {
      width: 100%;
      display: block;
      min-height: auto;
    }
    &:nth-child(1) {
      margin-right: 20px;
      padding: 40px 0 0 0;
      @media (max-width: 900px) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
    &:nth-child(2) {
      margin-left: 20px;
      padding: 40px 0 0 0;
      @media (max-width: 900px) {
        margin-left: 0;
      }
    }
    &:nth-child(1),
    &:nth-child(2) {
      display: inline-block;

      h2 {
        font-size: 27px;
        color: #21252f;
        letter-spacing: 0;
        line-height: 1;
        margin: 0 0 25px;
        @media (max-width: 900px) {
          font-size: 24px;
        }
        @media (max-width: 600px) {
          font-size: 21px;
        }
        @media (max-width: 450px) {
          font-size: 19px;
        }
      }
    }
    .inner-wrap {
      width: 100%;
      .txt-wrap {
        min-height: 200px;
        margin-bottom: 30px;
        @media (max-width: 900px) {
          min-height: auto;
        }
      }
    }
  }
}
