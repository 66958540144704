
#page-wrap-inner{
  .mobile-filter-wrap {
    display: none;
    .select_style {
      background: #FFFFFF;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.14);
      border-radius: 4px;
      font-size: 15px;
      color: #909090;
      font-weight: 500;
    }
    @media(max-width:960px) {
      display: block;
    }
    @media(max-width:450px) {
      margin-left:auto;
      margin-right:auto;
      width:calc(100% - 25px);
    }
  }
  .category-btn-wrap {
    display:flex;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.14);
    border-radius: 4px;
    margin-top:10px;
    margin-bottom:60px;
    font-size: 15px;
    color: #909090;
    font-weight: 500;
    overflow: hidden;
    background:#fff;
    @media(max-width:960px) {
      display: none;
    }
    span {
      //width:calc(100% / 6);
      text-align:center;
      padding:15px 10px;
      display:block;
      flex-grow: 1;
      background: #FFFFFF;
      cursor: pointer;
      border-left:1px #E9F0FA solid;
      position: relative;
      &:nth-child(1) {
        border-left:0;
      }
      &.active {
        background:#C2185D;
        color:#fff;
      }
      &:active {
        top:1px;
      }
    }
    @media(max-width:900px) {
      display:none;
    }
  }

  .portfolio-items {
      display:flex;
      flex-wrap:wrap;
      margin-left:-15px;
      margin-right:-15px;
      margin-top:50px;
      min-height:500px;
      //transition: all .2s ease-in-out;
      @media(max-width:900px) { margin-top:40px; }
      @media(max-width:600px) { margin-top:30px; }
      @media(max-width:450px) {
        margin-top:25px;
        margin-left:0;
        margin-right:0;
      }
  }

  .portfolio-item {
    padding:30px 17px 75px;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.10);
    border-radius: 4px;
    margin-left:15px;
    margin-right:15px;
    min-height:340px;
    width:240px;
    display:inline-block;
    margin-bottom:30px;
    cursor: pointer;
    width:calc(25% - 30px);

    @media(max-width:1050px) {
      width:calc(33.3333% - 30px);
    }
    @media(max-width:720px) {
      width:calc(50% - 30px);
    }
    @media(max-width:450px) {
      width:calc(100% - 30px);;
      min-height:0;
    }
    .img-wrap {
      height:100px;
      text-align:center;
      display:flex;
      justify-content:center;
      align-items:center;
      margin-bottom:20px;
      img {
        max-height:75px;
        max-width:80%;
        display:block;
        margin:0 auto;
      }
    }
    .txt-wrap {
      font-size: 13.5px;
      color: #5F6D79;
      letter-spacing: 0;
      line-height: 1.6;
      text-align:center;
      font-style: normal;
      font-weight: 300;
      min-height: 85px;
      .date{
        width: 100%;
        font-weight: 300;
        display: block;
        width: calc(100% - 34px);
        position: absolute;
        bottom: 81px;
      }
    }
    .btnwrap{
      width:calc(100% - 34px);
      position: absolute;
      bottom: 36px;
      .btn{
        font-size: 11px;
        color: #9e9e9e;
        padding: 5px 32px;
        max-width: 140px;
        text-align: center;
        display: block;
        background: #F0F0F0;
        margin: 0 auto;
      }
    }

    .btn-wrap {
      text-align:center;
      position: absolute;
      bottom:6px;
      width:100%;
      height: 70px;
      left:0;
      span.cat {
        background: #F0F0F0;
        border-radius: 4px;
        font-size: 12px;
        color: #9E9E9E;
        letter-spacing: 0;
        line-height:1;
        display:inline-block;
        margin:0 auto;
            margin-left: 2px;
        margin-right: 2px;
        margin-bottom: 4px;
        padding:7px 11px;
        font-style: normal;
        font-weight: 500;
      }

    }
    .more-details {
      display:none;
    }
    &:hover {
      transform: scale(1.05);
      text-decoration: none;
      transition: transform .2s ease-in-out;
    }
    &:focus {
      text-decoration: none;
    }
  }
}
