.subscribe-wrap {
  background: #21252f;
  padding: 65px 0;
  @media (max-width: 900px) {
    padding: 55px 0;
  }
  @media (max-width: 600px) {
    padding: 45px 0;
  }
  @media (max-width: 450px) {
    padding: 30px 0;
  }
  .inner-wrap {
    margin: 0 auto;
    max-width: 655px;
    text-align: center;
    h4 {
      font-size: 27px;
      color: #ca2059;
      letter-spacing: 0;
      font-weight: 500;
      margin: 0 0 20px;
      @media (max-width: 900px) {
        font-size: 24px;
      }
      @media (max-width: 600px) {
        font-size: 21px;
      }
      @media (max-width: 450px) {
        font-size: 19px;
      }
    }
    p {
      font-size: 15px;
      color: #ffffff;
      letter-spacing: 0;
      line-height: 1.75;
      margin-bottom: 30px;
      font-style: normal;
      font-weight: 300;
      @media (max-width: 600px) {
        font-size: 14px;
      }
      @media (max-width: 450px) {
        font-size: 13px;
      }
    }
    .validation-wrap {
      padding: 10px 25px;
      background: #feffbf;
      color: #ca2059;
      display: inline-block;
      margin-bottom: 15px;
      width: calc(100% - 10px);
      margin-left: auto;
      margin-right: auto;
      border-radius: 4px;
    }
    form {
      display: flex;
      flex-direction: column;
      .subscribe-input-items {
        input {
          width: calc(50% - 11px);
          display: inline-block;
          margin-right: 10px;
          @media (max-width: 460px) {
            width: calc(100%);
            display: block;
            margin: 0 0 10px;
          }
        }
      }
      .chk-wrap {
        display: flex;
        font-size: 14px;
        color: #cbcbcb;
        letter-spacing: 0;
        margin: 30px 0 40px;
        @media (max-width: 700px) {
          flex-wrap: wrap;
          text-align: left;
        }
        span {
          padding-right: 10px;
          @media (max-width: 700px) {
            flex-grow: 1;
          }
        }
        .chk-item {
          color: white;
          display: flex;
          margin-left: 20px;
          @media (max-width: 700px) {
            margin-left: 0;
            margin-top: 10px;
            width: calc(50%);
          }
          @media (max-width: 340px) {
            width: calc(100%);
          }
          label {
            display: block;
            font-size: 15px;
            color: #bd2050;
            letter-spacing: 0;
            @media (max-width: 700px) {
              order: 3;
            }
          }
          input {
            margin: 3.5px 0 0 7.5px;
            padding: 0;
            width: auto;
            font-size: 22px;
            @media (max-width: 700px) {
              margin-right: 7.5px;
            }
          }
        }
      }
      /*
    fieldset {
      display:inline-block;
      width:calc(100% - 170px);
      @media(max-width:700px) {
        display:block;
        width:calc(100%);
      }
      input {
        width:calc(50% - 11px);
        display:inline-block;
        margin-right:10px;
        @media(max-width:460px) {
          width:calc(100%);
          display:block;
          margin:0 0 10px;
        }
      }
    }*/
      button {
        display: inline-block;
        margin-top: 0;
        width: 160px;
        background: $pink;
        padding: 15px 0;
        @media (max-width: 700px) {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

footer {
  background: #1a1b20;
  color: #fff;
  padding-top: 50px;
  padding-bottom: 40px;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 25px;
  font-weight: 300;
  .top,
  .mid,
  .btm {
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    .col {
      &:nth-child(2) {
        text-align: right;
      }
    }
  }
  .top .col:nth-child(2) {
    font-size: 16px;
  }
  .btm,
  .mid {
    padding-top: 25px;
  }
  img {
    max-height: 29px;
  }
  a {
    color: #fff;
    text-decoration: underline;
    vertical-align: top;
    &:hover {
      color: $pink;
    }
    &.btn {
      display: inline-block;
      padding: 4.5px 15px;
      margin-right: 7px;
    }
    &.linkedin-btn,
    &.twitter-btn {
      display: inline-block;
      font-size: 20px;
      color: #fff;
      padding: 6px 8px;
      background: #000;
      border-radius: 4px;
      overflow: hidden;
      line-height: 1;
      &:hover {
      }
    }
    &.twitter-btn {
      margin-right: 7px;
      background-color: #000;
    }
    &.linkedin-btn {
      background-color: #0084b4;
    }
  }

  @media (max-width: 960px) {
    padding-top: 40px;
    padding-bottom: 30px;
    .top,
    .mid,
    .btm {
      flex-direction: column;
      .col {
        text-align: center;
        &:nth-child(2) {
          text-align: center;
        }
        img {
          margin-bottom: 10px;
        }
      }
    }
    .mid {
      .col:nth-child(1) {
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 600px) {
    font-size: 13px;
  }
  @media (max-width: 450px) {
    font-size: 12px;
  }
}
