.equity-header {
  padding-bottom:90px;
  @media(max-width:600px) { padding-bottom:30px; }
}

.what-we-do-wrap {

  padding:150px 0 30px 0;
  position: relative;
  @media(max-width:900px) { padding:110px 0; margin-bottom:0; }
  @media(max-width:600px) { padding:75px 0;  }
  @media(max-width:450px) { padding:50px 10px;  }

  .inner-wrap {
    max-width:870px;
    text-align:center;
    h2 {
      font-size: 33px;
      line-height: 1.25;
      font-style: normal;
      font-weight: 500;
      @media(max-width:900px) { font-size:28px; }
      @media(max-width:600px) { font-size:23px; }
      @media(max-width:450px) { font-size:20px; }
    }
    p {
      font-size: 15px;
      //color: rgba(101, 101, 101, 0.7);
      letter-spacing: 0;
      line-height: 1.35;
      margin:1.5em 0;
    }
    ul {
      display:flex;
      flex-wrap: wrap;
      margin-left:-10px;
      margin-right:-10px;
      margin-bottom:45px;
      @media(max-width:900px) { margin-bottom:35px; }
      @media(max-width:600px) { margin-bottom:25px; }
      @media(max-width:450px) { margin-bottom:20px; }
      li {
        width:calc(25% - 20px);
        margin-left:10px;
        margin-right:10px;
        margin-top:10px;
        margin-bottom:10px;
        background: #FFFFFF;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.09);
        border-radius: 4px;
        height:136px;
        padding:10px;
        display:flex;
        justify-content:center;
        align-items:center;
        @media(max-width:750px) { width:calc(50% - 20px); }
        @media(max-width:450px) { width:calc(100% - 20px); }
        h3, h4 {
          font-size: 16px;
          color: #CA2059;
          letter-spacing: 0;
          line-height: 1.5;
          font-style: normal;
          font-weight: 500;
          text-transform: none;
          @media(max-width:900px) { font-size:20px; }
          @media(max-width:600px) { font-size:19px; }
          @media(max-width:450px) { font-size:18px; }
        }
      }
    }
    a.btn {
      background:#41454D;
    }
  }
}

.cat-lists-wrap {
  //background:#21252F;

  text-align:center;
  position:relative;
  padding:0 0 130px 0;
  @media(max-width:900px) { padding:115px 0; }
  @media(max-width:600px) { padding:90px 0; }
  @media(max-width:450px) { padding:60px 0; }
  .inner-wrap {
    max-width:870px;
    h2 {
      font-size: 33px;
      line-height: 1.25;
      font-style: normal;
      font-weight: 500;
      @media(max-width:900px) { font-size:28px; }
      @media(max-width:600px) { font-size:23px; }
      @media(max-width:450px) { font-size:20px; }
    }

    ul {
      display:flex;
      flex-wrap: wrap;
      margin:35px -15px;
      @media(max-width:900px) {
        margin-left:-10px;
        margin-right:-10px;
      }
      @media(max-width:280px) {
        flex-direction: column;
      }
      li {
        height:210px;
        border-radius: 4px;
        margin-left:15px;
        margin-right:15px;
        margin-bottom:15px;
        margin-top:15px;
        width:calc(25% - 30px);
        @media(max-width:900px) {
          width:calc(50% - 20px);
          margin-left:10px;
          margin-right:10px;
          margin-bottom:10px;
          margin-top:10px;
        }
        @media(max-width:280px) {
          width:calc(100% - 20px);
        }
        .img-wrap {
          height:105px;
          display:flex;
          justify-content:center;
          align-items:center;
          margin-bottom:15px;
        }
        h3 {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 1.3;
          text-transform:capitalize;
          font-style: normal;
          font-weight: 500;
          @media(max-width:900px) { font-size:15px; }
          @media(max-width:600px) { font-size:14px; }
          @media(max-width:450px) { font-size:13px; }
        }

        &.card {
          cursor: pointer;
        }
        .front {
          padding:35px 15px;
          background: #262A34;
          box-shadow: 0 0 4px 0 rgba(0,0,0,0.19);
          border-radius:4px;
        }
        .back {
          padding:35px 15px;
          color:white;
          background: #262A34;
          display:flex;
          justify-content:center;
          align-items:center;
          border-radius:4px;
          box-shadow: 0 0 4px 0 rgba(0,0,0,0.19);
        }
      }
    }
  }

}

.cat-lists-wrap ~ .cat-lists-wrap {
  background:none;
  &:before {
    right:25%;
    border-top:0;
    border-left:0;
    border-right: 50px solid #21252F;
    border-bottom:58px solid #F2F2F2;
  }
  &:after {
    width:25%;
    background:#21252F;
  }
  .inner-wrap {
    ul {
      li {
        background: #FFFFFF;
        h3 {
          color: #808080;
        }
      }
    }
  }
}
