.slideshow {
  .slide {
    position: relative;
    height:813px;
    width:100%;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: right;
    max-height:100vh;
    @media(max-width:900px) { height:613px; }
    @media(max-width:600px) { height:413px; }
    @media(max-width:450px) { height:313px; }
    .img-overlay {
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      opacity: 0.28;
      background-image: linear-gradient(90deg, #000000 50%, rgba(0,0,0,0.00) 78%);
    }
    .txt-wrap {
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      .inner-wrap {
        display:flex;
        align-items:center;
        height:100%;
        div {
          max-width:470px;
        }
        h1 {
          font-size: 124px;
          color: #fff;
          letter-spacing: 0;
          line-height:0.9;
          text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
          margin:0;
          @media(max-width:900px) { font-size:99px; }
          @media(max-width:600px) { font-size:79px; }
          @media(max-width:450px) { font-size:60px; }
        }
        p {
          font-size: 28px;
          letter-spacing: 0;
          line-height:1;
          text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
          margin:10px 0 0;
          font-family:$title-font-family;
          font-weight:500;
          font-style: normal;
          color:#fff;
          @media(max-width:900px) { margin:25px 0; font-size:24px; }
          @media(max-width:600px) { margin:15px 0; font-size:20px; }
          @media(max-width:450px) { margin:5px 0; }

        }
        .btn-wrap {
          a {
            font-size: 20px;
            color: #000D4A;
            letter-spacing: 0.91px;
            margin-right:10px;
            padding:20px 30px;
            @media(max-width:900px) { font-size:18px; }
            @media(max-width:600px) { font-size:16px; }
            @media(max-width:450px) { font-size:15px; }
            &:nth-child(1) {

            }
            &:nth-child(2) {


            } 
          }
        }
      }
    }
  }
}