.news-items {
  margin-left:-15px;
  margin-right:-15px;
  @media screen and (max-width: 600px) {
    margin-left:0;
    margin-right:0;
  }
  .item {
    margin-left:15px;
    margin-right:15px;
    margin-bottom:30px;
    @media screen and (max-width: 600px) {
      margin-left:0;
      margin-right:0;
    }
    background: #FFFFFF;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.15);
    border-radius: 4px;
    overflow: hidden;
    .img-wrap {
      display:block;
      img { 
        width:100%;
      }
    }
    .info-wrap {
      padding-top:30px;
      padding-bottom: 10px;
      padding-left:30px;
      padding-right:30px;
      display:flex;
      justify-content: space-between;
      position: relative;
      &:after {
        content: " ";
        position: absolute;
        background: #AF2047;
        height:8px;
        width:106px;
        left:30px;
        top:-4px;
      }
      @media(max-width:600px) { 
        padding-top: 20px;
        padding-left:20px;
        padding-right:20px;
      }
      @media(max-width:450px) { 
        padding-top: 15px;
        padding-left:15px;
        padding-right:15px;
      }
      @media(max-width:300px) { 
        display:block;
        margin-top:4px;
        span {
          display: block;
        }
      }
      .cat { 
        background: #BD2050;
        font-weight: 500;
        font-size: 10px;
        color: #FFFFFF;
        letter-spacing: 0;
        border-radius:3px;
        display:inline-block;
        padding:7px 20px 7px 10px;
        text-transform: uppercase;
        position: relative;
        line-height: 1;
        &:before {
          content: " ";
          position: absolute;
          top: -1px;
          right: -17px;
          height: 18px;
          width: 18px;
          transform-origin: top left;
          transform: rotate(45deg);
          background: #BD2050;
          border-radius: 3px;
        }
        &:after {
          content: " ";
          background: #FFFFFF;
          width:6px;
          height:6px;
          border-radius:3px;
          position: absolute;
          top:8px;
          right:-1px;
        }
       }
      .date {
          font-size: 10px;
          color: #484747;
          letter-spacing: 0;
          line-height: 1.3;
          font-style: normal;
          font-weight: 500;
          text-transform: uppercase;
          padding-top:4px;
       }
    }
    .txt-wrap {
      padding-bottom: 30px;
      padding-left:30px;
      padding-right:30px;
      text-align:left;
      position: relative;
      @media(max-width:600px) { 
        padding-bottom: 20px;
        padding-left:20px;
        padding-right:20px;
      }
      @media(max-width:450px) { 
        padding-bottom: 15px;
        padding-left:15px;
        padding-right:15px;
      }
      h2 { 
        text-transform: none;
        font-size: 21px;
        letter-spacing: 0;
        line-height: 1.45;
        font-style: normal;
        font-weight: 500;
        margin-bottom:10px;
        @media(max-width:900px) { font-size:19px; }
        @media(max-width:600px) { font-size:17px; }
        @media(max-width:450px) { font-size:16px; } 
        a { 
          color: #CA2059;
        }
      }
      p { 
        font-size: 13.5px;
        color: #727272;
        letter-spacing: 0;
        line-height: 2;
        font-style: normal;
        font-weight: 300;
      }
    }
    &[data-type="events"] {
      .info-wrap {
        &:after {
          background: #949DC1;
        }
        .cat { 
          background-color:#1F212A; 
          &:before {
            background: #1F212A;
          }
        }
      }
    }
  }
}

.events-items {
  @extend .news-items;
  .item {
    .info-wrap {
      &:after {
        background: #949DC1;
      }
      .cat { 
        background-color:#1F212A; 
        &:before {
          background: #1F212A;
        }
      }
    }
  }
}


.news-item-container {
  padding:70px 0;
  .inner-wrap {
    max-width:722px;
    .news-item-header {
      text-align:center;
      h1 { 
        font-size: 38px;
        color: #CA2059;
        letter-spacing: 0;
        line-height: 1.3;
        font-style: normal;
        font-weight: 700;
        margin:0 0 20px;
        @media(max-width:900px) { font-size:34px; }
        @media(max-width:600px) { font-size:30px; }
        @media(max-width:450px) { font-size:28px; } 
      }
      h3 { 
        font-size: 13px;
        color: #505050;
        letter-spacing: 0;
        line-height: 1.8;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 500;
        margin:0 0 20px;
        @media(max-width:600px) { font-size:12px; }
      }
    }
    .event-details {
      display:flex;
      background: #FFFFFF;
      box-shadow: 0 0 6px 0 rgba(0,0,0,0.14);
      border-radius: 4px;
      overflow: hidden;
      font-size: 13px;
      color: #909090;
      letter-spacing: 0;
      margin:45px 0;
      .events-details-info {
        flex-grow:1;
        display:flex;
        //flex-wrap: wrap;
        width:calc(100% - 200px);
        justify-content:space-evenly;
      }
      .col {
        line-height:42px;
        padding:0 20px;
        text-align:left;
        height:100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display:inline-block;
        &:nth-child(2) {
          border-left:1px rgba(#E9F0FA, 0.38) solid;
          border-right:1px rgba(#E9F0FA, 0.38) solid;
        }
        &.date-ico,
        &.time-ico,
        &.location-ico {
          &:before {
            font-family: FontAwesome;
            display: inline-block;
            vertical-align: middle;
            font-size:20px;
            position: relative;
            top:-1px;
            width:35px;
            text-align:center;
            color:rgba(#000, 0.37);
            height:100%;
          }
        }
        &.date-ico { 
          &:before {
            content: "\f274";
          }
        }
        &.time-ico {
          &:before {
            content: "\f017";
          }

         }
        &.location-ico {
          &:before {
            content: "\f041";
          } 
        }
        &.btn {
          border-top-left-radius:0;
          border-bottom-left-radius:0;
        }
      }
      .events-details-btn {
        a.btn {
          white-space: normal;
          overflow: unset;
        }
      }
      @media(max-width:960px) {
        display:block;
        .events-details-info  {
          width:100%;
        }
        .events-details-btn {
          width:100%;
          a.btn {
            width:100%;
            text-align:center;
            border-top-right-radius: 0;
          }
        }
      }
      @media(max-width:600px) {
        .events-details-info  {
          flex-direction: column;
          .col {
            &:nth-child(2) {
              border-left:0;
              border-right:0;
              border-top:1px rgba(#E9F0FA, 0.38) solid;
              border-bottom:1px rgba(#E9F0FA, 0.38) solid;
            }
          }
        }
      }
    }
    .img-wrap {
      background: #FFFFFF;
      border: 1px solid #D7D7D7;
      padding:10px;
      margin-top:24px;
      margin-bottom:20px;
      img { 
        width:100%;
      }
    }
    .news-body {
      margin:0 20px;
      .general-txt { 
        font-size: 15px;
        color: #545454;
        letter-spacing: 0;
        line-height: 1.75;
        font-style: normal;
        font-weight: 300;
        @media(max-width:450px) { font-size:14px; }
        p {
          margin-top:0.75em;
          margin-bottom:1.5em;
          &:nth-child(1) {
            font-size: 19px;
            color: #545454;
            letter-spacing: 0;
            line-height: 1.75;
            font-style: normal;
            font-weight: 400;
            margin-bottom:1em;
            @media(max-width:900px) { font-size:18px; }
            @media(max-width:600px) { font-size:17px; }
            @media(max-width:450px) { font-size:16px; }
          }
        }
        a {
          color:$pink;
          text-decoration: underline;
          font-style: normal;
          font-weight: 500;
        }
        h2 {
          margin:10px 0 0;
        }
        iframe {
          width:100% !important;
        }
      }
    }
  }
}

.blank-events-wrap {
  p {
    font-size: 15px;
    color: rgba(101,101,101,0.70);
    letter-spacing: 0;
    line-height: 1.65;
    max-width:640px;
  }
  form {
    margin:40px auto;
    display:flex;
    width:100%;
    @media(max-width:800px) {
      display:block;
      text-align:center;
    }
    fieldset {
      width:calc(100% - 175px);
      @media(max-width:800px) {
        width:calc(100%);
      }
      input {
        background: #FFFFFF;
        border: 1px solid #F4F4F4;
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.15);
        width:calc(50% - 20px);
        margin-right:20px;
        margin-bottom:0;
        @media(max-width:800px) {
          width:calc(50% - 10px);
          &:nth-child(2) {
            margin-right:0;
          }
        }
        @media(max-width:800px) {
          width:100%;
          &:nth-child(2) {
            margin-top:20px;
          }
        }
      }
    }
    button {
      background:#BD2050;
      width:175px;
      font-size:14px;
      margin:0;
      @media(max-width:800px) {
        margin-top:15px;
        padding:16.5px 25px;
      }
    }
  }
}


.news-header-top {
  display: flex;
  justify-content: space-between;
  align-items:center;
  margin-bottom:45px;
  h1 {
    margin-bottom:0;
  }
  .category-btn-wrap {
    margin-top:0;
    margin-bottom:0;
    span {
      padding:9px 15px;
    }
  }
}

#news-filter {
  .item {
    width:calc(33.333% - 30px);
    @media(max-width:900px) { width:calc(50% - 30px); }
    @media(max-width:600px) { width:calc(100%); }
  }
}