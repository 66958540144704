
$title-font-family:	"roboto";
$standard-font-family: "roboto";

$container: 1100;

$navy: #21252F;
$pink: #CA2059;
$grey: #545454;


$break-small: 400px;
$break-large: 1130px;
$mobile-nav: 995px;
