.homepage.scotland {
  .figures-header {
    text-align: center;
    padding: 60px 0;
    @media (max-width: 900px) {
      padding: 45px 0;
    }
    @media (max-width: 600px) {
      padding: 35px 0;
    }
    @media (max-width: 450px) {
      padding: 25px 0;
    }
  }
  header.page-header .inner-wrap .page-header-inner p {
    margin-top: 15px;
  }
}
.scotland-txt-area {
  background: #fff;
  padding: 60px 0;
  text-align: center;
  h1 {
    font-size: 22px;
    color: #2d2d2d;
    line-height: 1.25;
    margin-bottom: 35px;
  }
  p {
    max-width: 760px;
    margin: 0 auto;
    font-size: 15px;
    color: rgba(101, 101, 101, 0.7);
    letter-spacing: 0;
    text-align: center;
    line-height: 1.35;
  }

  img {
    margin-top: 35px;
  }
}
